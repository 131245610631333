<template>

  <router-view :key="$route.fullPath"/>
   <!-- <a id="skippy" class="visually-hidden-focusable" href="#content">
    <div class="container">
      <span class="skiplink-text">Skip to main content</span>
    </div>
  </a> -->
</template>

<style>
</style>
