import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contents/:content_id",
    name: "Contents",
    component: () =>
      import(/* webpackChunkName: "contents" */ "../views/Page/Contents.vue"),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: "/categorys/:catid",
    name: "Categorys",
    component: () =>
      import(/* webpackChunkName: "categorys" */ "../views/Page/Categorys.vue"),
    props: true,
  },
  {
    path: "/searchs/:search_id",
    name: "Searchs",
    component: () =>
      import(/* webpackChunkName: "searchs" */ "../views/Page/Searchs.vue"),
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/User/Login.vue"),
  },
  {
    path: "/subscribes",
    name: "Subscribes",
    component: () =>
      import(
        /* webpackChunkName: "subscribes" */ "../views/User/Subscribes.vue"
      ),
  },
  {
    path: "/unsubscribes",
    name: "Unsubscribes",
    component: () =>
      import(
        /* webpackChunkName: "unsubscribes" */ "../views/User/Unsubscribes.vue"
      ),
      meta: {
        IsAuth: true
      }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFounds",
    component: () =>
      import(/* webpackChunkName: "notFounds" */ "../views/Page/NotFounds.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      next('/Subscribes')
      // next(window.open("https://he.zaindsp.com:3033/?p=9275614755", "_self"));
    }
   } else {
     next();
   }
})

export default router;
